exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-choose-a-guide-js": () => import("./../../../src/pages/choose-a-guide.js" /* webpackChunkName: "component---src-pages-choose-a-guide-js" */),
  "component---src-pages-dive-deeper-js": () => import("./../../../src/pages/dive-deeper.js" /* webpackChunkName: "component---src-pages-dive-deeper-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-self-guided-sessions-js": () => import("./../../../src/pages/self-guided-sessions.js" /* webpackChunkName: "component---src-pages-self-guided-sessions-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-the-safe-harbor-method-js": () => import("./../../../src/pages/the-safe-harbor-method.js" /* webpackChunkName: "component---src-pages-the-safe-harbor-method-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */)
}

