import "./src/styles/global.css";


const smoothLoading = () => {
    const observer = new IntersectionObserver((entries, long = false) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting) {
            setTimeout(() => {
                entry.target.classList.add('show');
            }, long ? 200 : 100)
          } else {
          }
        })
    });
    
    const toShow = document.querySelectorAll('section');
    toShow.forEach((el) => observer.observe(el, true));
}

export const onRouteUpdate = () => {
    smoothLoading();
};

export const onInitialClientRender = () => {
    smoothLoading();
};
  